<template>
  <div class="page">
    <!-- <div class="content">{{ search || "暂无参数" }}</div> -->
    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <header class="header">
        <img
          :src="
            bannerList && bannerList.topicPicture
              ? bannerList.topicPicture
              : require('./img/header_bg.png')
          "
          alt="图片加载中..."
        />
      </header>
      <div class="main">
        <div class="nav">
          <div
            v-for="item in navList"
            :key="item.key"
            :class="{ 'nav-index': navKey === item.key }"
            @click="setNavKey(item.key)"
          >
            <span>{{ item.label }}</span
            ><span class="spot" v-show="navKey === item.key"></span>
            <!-- <div class="line"></div> -->
          </div>
          <!-- <div class="nav-new" :class="{ 'nav-index': navLabel === 'new' }">
            <span>最新</span>
          </div>
          <div class="nav-hot" :class="{ 'nav-index': navLabel === 'hot' }">
            <span>热门</span>
          </div> -->
        </div>
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="list-content"
            v-for="(item, index) in listData"
            :key="index"
            @click="toRouteTitle(item)"
          >
            <div class="list-sidebar">
              <img :src="item.topicPicture" />
            </div>
            <div class="list-wrapper">
              <div class="left-title">
                <div class="img" v-if="item.isTop == 1">
                  <img src="./img/hot.png" />
                </div>
                <div class="name">{{ item.topicTitle }}</div>
              </div>
              <div class="left-content">
                {{ item.remark || "" }}
              </div>
              <div class="date">
                {{ item.createTime || "" }}
              </div>
              <div class="left-wrap">
                <!-- <div class="iconTag" v-if="item.nowStatus">
                  <span>{{ item.nowStatus }}</span>
                </div> -->
                <div class="iconText">
                  <div class="icon">
                    <img src="./img/icon-hot.png" />
                  </div>
                  <div class="text">
                    <span>{{ item.readCount }}</span>
                  </div>
                </div>
                <!-- <div class="iconText">
                  <div class="icon icon-up">
                    <img v-if="!item.hasPraised" src="./img/icon-like.png" />
                    <img v-else src="./img/icon-like-red.png" alt="" />
                  </div>
                  <div class="text">
                    <span :class="{ hasPraised: item.hasPraised }">
                      {{ item.praiseCount }}
                    </span>
                  </div>
                </div> -->
                <div class="iconText">
                  <div class="icon icon-up">
                    <img src="./img/icon-msg.png" />
                  </div>
                  <div class="text">
                    <span>{{ item.commentCount }}</span>
                  </div>
                </div>
                <div class="iconText" v-if="item.signinCount != ''">
                  <div class="icon icon-up">
                    <img src="./img/icon-signFor.png" />
                  </div>
                  <div class="text">
                    <span>{{ item.signinCount }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </v-refresh>
    <!-- <footer v-if="!isApply">
      <div class="btn" @click="apply">
        <span>我要申请</span>
      </div>
    </footer> -->
    <v-comments
      ref="vComments"
      v-model="text"
      isAutoClose
      :show.sync="isShowComments"
      @acceptSend="acceptSend"
    ></v-comments>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import {
  getTopicListURL,
  cancelPraiseURL,
  addPraiseURL,
  addCommentURL,
  getUserTalentStatusURL,
  getBannerListUrl,
} from "./api.js";
import { vComments } from "@/components/control/index";
import { gloabalCount } from "@/utils/common.js";

export default {
  name: "topicList",
  components: {
    vComments,
  },
  data() {
    return {
      isShowComments: false,
      navKey: "NEW",
      navList: [
        {
          label: "最新",
          key: "NEW",
        },
        {
          label: "热门",
          key: "HOT",
        },
      ],
      isApply: false, // 是否已经申请
      text: "", // 文本内容
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curData: null,
      curPage: 1,
      pageSize: 20,
      curIndex: undefined, // 当前点击的索引
      search: "",
      bannerList: {},
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.search = this.$route.query;
    // this.getIsApply();
  },
  mounted() {
    this.getBannerList();
    gloabalCount("", 21, 1);
  },
  methods: {
    async getBannerList() {
      let params = {
        tenantId: this.tenantId,
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
        topicType: 53, // 1. 和伙人风采 2.超话 3. 活动 6.创业达人
        curPage: 1,
        pageSize: 10,
      };
      let res = await this.$axios.get(`${getBannerListUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        if (res.data.records) {
          this.bannerList = res.data.records[0];
        }
      } else {
      }
    },
    setNavKey(val) {
      if (val === this.navKey) {
        return;
      }
      this.navKey = val;
      this.onRefresh();
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicListURL, {
          params: {
            tenantId: this.tenantId,
            houseId: this.houseId || this.communityId || undefined,
            userId: this.userId,
            status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            topicType: 4, // 1. 和伙人风采 2.超话 3. 活动 4.春城广播站
            curPage: this.curPage,
            pageSize: this.pageSize,
            sortField: this.navKey, // 排序方式， 最热 or 最新
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.listData = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            if (this.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.listData.forEach((ele) => {
              if (ele.topicPicture) {
                ele.topicPicture = this.$handleImg(192, 192, ele.topicPicture);
              }
            });
            this.curPage++;
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onRefresh() {
      this.curPage = 1;
      // this.listData = [];
      this.onLoad();
    },
    // 接受评论组件发送过来的信息
    acceptSend(val) {
      if (!(val.trim && val.trim())) {
        this.$toast({
          message: "请输入内容",
          duration: 1500,
        });
        return;
      }
      console.log(val, this.text);
      const item = this.curData;
      // 1. 调接口
      this.$toast.loading({
        // duration: 1500, // 持续展示 toast
        forbidClick: true,
        message: "发送中...",
      });
      this.$axios
        .post(addCommentURL, {
          skipImageScan: "skip",
          topicId: item.id, // 话题id
          commentId: null, // 评论id
          // parentId: "", // 上级回复id 预留字段，评论类型为2的场合才可能有值，将来如果要实现回复树状展示时可以利用
          commentType: 0, // 评论类型 0：针对话题的评论；1：针对评论的回复；2：针对回复的回复
          commentContent: this.text, // 评论内容
          fromUser: this.userId, // 评论人ID
          toUser: null, // 被回复人ID
          tenantId: this.tenantId || undefined,
          communityId: this.communityId || undefined,
          roomId: this.houseId || undefined,
        })
        .then((res) => {
          if (res.code === 200) {
            // 2. 关闭弹框
            this.isShowComments = false;
            // 3. 关闭后清楚数据
            this.text = "";
            item.commentCount += 1;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    // 点赞和取消点赞
    toClickLike(item) {
      this.$axios
        .post(item.hasPraised ? cancelPraiseURL : addPraiseURL, null, {
          params: {
            targetId: item.id,
            userId: this.userId,
            tenantId: this.tenantId || undefined,
            communityId: this.communityId || undefined,
            roomId: this.houseId || undefined,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            console.log(res, "----");
            if (item.hasPraised) {
              // 取消点赞
              item.hasPraised = false;
              item.praiseCount -= 1;
            } else {
              // 点赞
              item.hasPraised = true;
              item.praiseCount += 1;
            }
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          // this.isLoading = false;
        });
    },
    // 点击跳转话题详情页
    toRouteTitle(item) {
      // this.$router.push({
      //   name: "topicTitle",
      //   query: {
      //     id: item.id,
      //   },
      // });
      if (item.editType == 0) {
        let params = {
          id: item.id,
        };
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/broadcastDetail/broadcastTitle?params=${JSON.stringify(
              params
            )}`,
          });
        }
      } else if (item.editType == 1) {
        wx.miniProgram.navigateTo({
          url: `/pages/webview/index?url=${item.externalUrl}`,
        });
      }
    },
    // 点击评论图标跳出评论页面
    toComments(item) {
      this.curData = item;
      this.isShowComments = true;
      // 获取焦点
      setTimeout(() => {
        this.$refs.vComments.getFocus();
      }, 500);
    },
    getIsApply() {
      this.$toast.loading({
        // duration: 1500, // 持续展示 toast
        forbidClick: true,
        message: "请求中...",
      });
      this.$axios
        .get(getUserTalentStatusURL, {
          params: {
            userId: this.userId, // 评论人ID
            tenantId: this.tenantId,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.isApply = res.data;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    // 我要申请按钮
    apply() {
      this.$router.push({
        name: "talent",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  // background: #f5f5f5;
  // min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);

  /* 兼容 iOS >= 11.2 */
  ::v-deep .van-pull-refresh {
    min-height: 100vh;
  }

  header.header {
    width: 100%;
    height: 334px;
    overflow: hidden;
    padding: 30px 30px 0px;
    box-sizing: border-box;
    z-index: 2;

    > img {
      height: 100%;
      width: 100%;
    }
  }

  .main {
    box-sizing: border-box;
    margin: 0px 0 0 0;

    // padding: 0px 0px 116px 0px; // 底部内边距需加上 “按钮” 高度
    .nav {
      box-sizing: border-box;
      height: 100px;
      display: flex;
      justify-content: center;
      padding: 28px 0;

      > div {
        flex: 1;
        height: 40px;
        display: flex;
        font-size: 36px;
        justify-content: center;
        align-items: center;
        position: relative;

        span {
          color: #aaa7ff;
        }

        .spot {
          width: 10px;
          height: 10px;
          background: #ffb92d;
          border-radius: 50%;
          position: relative;
          top: -15px;
          left: 2px;
        }

        .line {
          display: none;
        }
      }

      .nav-index {
        font-weight: bold;

        span {
          color: #5e5afe;
        }

        .line {
          position: absolute;
          bottom: -28px;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          height: 8px;
          width: 64px;
          background: #007eff;
          border-radius: 4px;
        }
      }

      // 样式一

      // 样式二
      // padding: 32px;
      // font-size: 32px;
      // display: flex;
      // justify-content: flex-end;
      // > div {
      //   margin-left: 16px;
      //   padding: 12px 36px;
      //   border: 2px solid #007eff;
      //   border-radius: 32px;
      //   font-size: 28px;
      //   display: flex;
      //   color: #007eff;
      //   > span {
      //     line-height: 1;
      //     vertical-align: middle;
      //   }
      //   // line-height: 46px;
      // }
      // .nav-index {
      //   background: #007eff;
      //   color: #fff;
      // }
    }

    .list-content {
      border-radius: 16px;
      background: #ffffff;
      display: flex;
      overflow: hidden;
      margin: 0 32px 32px 32px;
      height: 256px;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);

      .list-sidebar {
        width: 256px;
        min-width: 256px;
        height: 256px;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .list-wrapper {
        flex: 1;
        max-width: calc(100% - 256px);
        padding: 0 32px;

        .left-title {
          display: flex;
          align-items: center;
          margin: 26px 0px 0px 0px;

          .img {
            width: 32px;
            height: 32px;
            font-size: 0;
            margin-right: 8px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .name {
            font-size: 32px;
            font-weight: bold;
            color: #333333;
            line-height: 1.1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .left-content {
          color: #666666;
          // height: 36px;
          font-size: 24px;
          font-weight: 400;
          color: #666666;
          line-height: 36px;
          margin: 6px 0;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .date {
          color: #666666;
          // height: 36px;
          font-size: 24px;
          font-weight: 400;
          color: #666666;
          line-height: 36px;
          margin: 6px 0;
          margin-top: 60px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .left-wrap {
          display: flex;

          align-items: center;

          // line-height: 32px;
          .iconTag {
            flex: 1;
            overflow: hidden;
            margin-right: 8px;

            > span {
              max-width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              // height: 40px;
              border-radius: 4px;
              box-sizing: border-box;
              font-size: 24px;
              line-height: 36px;
              display: inline-block;
              background: #fff3eb;
              border-radius: 8px;
              border: 2px solid #ffc7a4;
              padding: 4px 8px;
              color: #ffc7a4;
              vertical-align: middle;
            }
          }

          .iconText {
            display: flex;
            color: #a8a8a8;
            align-items: center;

            &:not(:last-child) {
              margin-right: 16px;
            }

            .icon {
              // overflow: hidden;
              display: flex;
              align-items: center;
              font-size: 0;

              > img {
                width: 36px;
                height: 36px;
                // width: 100%;
                // height: 100%;
              }
            }

            .text {
              margin-left: 4px;
              display: flex;
              align-items: center;

              span {
                &.hasPraised {
                  color: #ff853a;
                }

                font-size: 24px;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }

  footer {
    position: fixed;
    z-index: 99;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding-bottom: constant(safe-area-inset-bottom);
    /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom);

    /* 兼容 iOS >= 11.2 */
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 96px;
      color: #ffffff;
      background: #007eff;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
    }
  }
}
</style>
